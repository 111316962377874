/* 기본 스타일 */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 1280px;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  cursor: pointer;
  margin: 0 32px;
}

.nav-links a {
  font-family: "AvenirNext-Medium";
  font-size: 16px;
  text-decoration: none;
  color: #c5cbd9;
}

.nav-links a:hover {
  color: #ffc813;
}

/* 모바일 메뉴 숨기기 */
.nav-links-mobile {
  display: none;
}

/* 모바일 버튼 */
.mobile-menu-icon {
  padding-top: 5px;
  font-size: 28px;
  color: #d9dfee;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .navbar {
    padding: 12px 24px;
  }

  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #000;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin-top: 0;
  }

  .nav-links-mobile li {
    text-align: left;
    padding: 12px 24px;
  }
  .nav-links-mobile li a {
    color: #fff;
    text-decoration: none;
    font-family: "AvenirNext-DemiBold";
    font-size: 14px;
  }

  .mobile-menu-icon {
    display: block;
  }
}
.lan_select {
  position: relative;
  width: 73px;
  border: 2px solid #313337;
  background-color: #000;
  border-radius: 12px;
  padding: 8px 10px;
  cursor: pointer;
}
.lan_select_label {
  font-size: 14px;
  margin-left: 4px;
  text-align: center;
  color: #d9dfee;
}
.lan_select_options {
  position: absolute;
  list-style: none;
  top: 18px;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
  background-color: #222222;
  color: #fefefe;
}

.lan_select_option {
  font-size: 14px;
  padding: 6px 8px;
  transition: background-color 0.2s ease-in;
}
.nav_wrap {
  position: fixed;
  top: 0;
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}
