html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "AvenirNext-Medium", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "AvenirNext-Bold";
  font-weight: normal;
  src: url("./fonts/AvenirNext-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext-Medium";
  font-weight: normal;
  src: url("./fonts/AvenirNext-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext-Regular";
  font-weight: normal;
  src: url("./fonts/AvenirNext-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext-DemiBold";
  font-weight: normal;
  src: url("./fonts/AvenirNext-DemiBold.ttf") format("truetype");
}
.B40_B {
  font-family: "AvenirNext-Bold";
  font-size: 40px;
  color: #fff;
}
.B22_B {
  font-family: "AvenirNext-Bold";
  font-size: 22px;
}
.B18_DB {
  font-family: "AvenirNext-DemiBold";
  font-size: 18px;
  color: #fff;
}
.B16_DB {
  font-family: "AvenirNext-DemiBold";
  font-size: 16px;
}
.B16_M {
  font-family: "AvenirNext-Medium";
  font-size: 16px;
}
.B14_B {
  font-family: "AvenirNext-Bold";
  font-size: 14px;
}
.B14_DB {
  font-family: "AvenirNext-DemiBold";
  font-size: 14px;
}
.B14_M {
  font-family: "AvenirNext-Medium";
  font-size: 14px;
}
.B13_M {
  font-family: "AvenirNext-Medium";
  font-size: 13px;
}
.B12_M {
  font-family: "AvenirNext-Medium";
  font-size: 12px;
}
.B12_DB {
  font-family: "AvenirNext-DemiBold";
  font-size: 12px;
}
.white {
  color: #fff;
}
.App {
  background-color: #000;
  flex-direction: column;
  justify-items: center;
  padding-top: 65px;
}
.main_wrap {
  flex: 1;
  height: 420px;
  background-image: url("./images/background.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 100% 30%;
}
.title_wrap {
  padding-top: 100px;
  max-width: 1280px;
  width: 100%;
}
.title {
  max-width: 520px;
}
#about img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.about_item {
  padding: 27px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
.about_item_label {
  width: 110px;
  font-family: "AvenirNext-DemiBold";
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.about_back_01 {
  background-image: url("./images/about_back_01.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.about_back_02 {
  background-image: url("./images/about_back_02.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;
  padding: 80px 24px;
  box-sizing: border-box;
}
.section h1 {
  font-family: "AvenirNext-Bold";
  font-size: 40px;
  color: #fff;
  margin-top: 80px;
}
#team {
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  background: linear-gradient(#ffae00, #fffdf6);
}
.team_title {
  margin: 32px 0;
}
.team_card {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#ffffff, #fff2b6);
  border-radius: 40px;
  padding: 12px;
  padding-bottom: 24px;
}
.team_card_title {
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 16px;
  background-color: #ffc813;
  color: #fff;
  padding: 4px 10px;
  width: fit-content;
}
.team_card_name {
  margin: 8px 0;
}
.team_card img {
  width: 94px;
  height: 94px;
}
.team_card_desc {
  text-align: center;
}
#download {
  background-color: #fff;
}
#download img {
  width: 108px;
  height: 108px;
  margin-top: 40px;
}
.version_text {
  margin-top: 16px;
  margin-bottom: 32px;
}
.vision_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.download_button {
  background-color: #ffc813;
  border-radius: 20px;
  padding: 13px 41px;
  color: #000;
  text-decoration: none;
}

.gray850 {
  color: #1a1b1e;
}

#link {
  color: #fff;
}

.link_item_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #313337;
  padding: 11px 16px;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
  column-gap: 20px;
}
.link_item_wrap > div {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link_item_wrap div:last-child {
  text-align: right;
}
.link_item_link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.link_item_link div {
  max-width: 138px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
.link_item_link img {
  width: 11px;
  height: 20px;
  margin-left: 16px;
}
.link_item_title {
  text-align: left;
  width: 100%;
}
#token {
  color: #fff;
}
.token_wrap {
  max-width: 680px;
  width: 100%;
}
.token_main_wrap {
  background-color: #313337;
  border-radius: 24px;
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 40px;
}
.token_main_wrap img {
  margin-top: 24px;
  width: 100%;
  max-width: 300px;
}
.token_card_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 56px;
  row-gap: 24px;
}
.token_card {
  box-sizing: border-box;
  max-width: 312px;
  border-radius: 24px;
  background-color: #313337;
  padding: 24px 32px;
  height: fit-content;
  .token_card_item:last-child {
    margin-bottom: 0px;
  }
}
.token_card_title {
  text-align: center;
  color: #ffae00;
  margin-bottom: 16px;
}
.token_card_item {
  padding: 8px 16px;
  background-color: #000;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  text-align: center;
}
.token_card_item_seq {
  font-family: "AvenirNext-Bold";
  font-size: 11px;
  color: #000;
  background-color: #fff;
  width: 19px;
  height: 19px;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .section {
    padding: 20px 24px;
  }
  .section h1 {
    font-size: 24px;
  }
  .title {
    font-size: 24px;
    padding: 0px 24px;
  }
  .main_wrap {
    background-size: cover;
    background-position: 0 100px;
  }
  .title_wrap {
    padding-top: 40px;
  }
  .token_card {
    width: 100%;
    max-width: none;
  }
}
